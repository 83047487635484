export default function changeNameImages(url) {
  return {
    sm: url.replace(/.png|.jpg/g, function(x) {
      return '-sm' + x;
    }),
    sm_avif: url.replace(/.png|.jpg/g, '-sm.avif'),
    sm_webp: url.replace(/.png|.jpg/g, '-sm.webp'),
    sm2: url.replace(/.png|.jpg/g, function(x) {
      return '-sm@2' + x;
    }),
    sm2_avif: url.replace(/.png|.jpg/g, '-sm@2.avif'),
    sm2_webp: url.replace(/.png|.jpg/g, '-sm@2.webp'),
    md: url.replace(/.png|.jpg/g, function(x) {
      return '-md' + x;
    }),
    md_avif: url.replace(/.png|.jpg/g, '-md.avif'),
    md_webp: url.replace(/.png|.jpg/g, '-md.webp'),
    md2: url.replace(/.png|.jpg/g, function(x) {
      return '-md@2' + x;
    }),
    md2_avif: url.replace(/.png|.jpg/g, '-md@2.avif'),
    md2_webp: url.replace(/.png|.jpg/g, '-md@2.webp'),
    desk: url.replace(/.png|.jpg/g, function(x) {
      return '' + x;
    }),
    desk_avif: url.replace(/.png|.jpg/g, '.avif'),
    desk_webp: url.replace(/.png|.jpg/g, '.webp'),
    desk2: url.replace(/.png|.jpg/g, function(x) {
      return '@2' + x;
    }),
    desk2_avif: url.replace(/.png|.jpg/g, '@2.avif'),
    desk2_webp: url.replace(/.png|.jpg/g, '@2.webp')
  };
}