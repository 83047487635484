import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import changeNameImages from 'utils/changeNameImages';
import { Toastify } from 'components/toastify/toastify';
import iconMail from '../../assets/icons/i-mail.svg';
import SEO from 'providers/HelmetProvider';
import Layout from 'components/routes/Layout';

const RecoverPassword = () => {
  const bg = changeNameImages('/assets/images/forms/bg.jpg');
  const toastifyRef = useRef();

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [serverError, setServerError] = useState(null);

  const onRecoverPassword = async (data) => {
    setServerError(null);
    try {
      const response = await fetch(
        (`${ process.env.REACT_APP_SERVER_URL }${ process.env.REACT_APP_ENDPOINT_RESETPASSWORD }`).replace('@userEmail', data.email),
        {
          method: 'GET',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      );
      if (response.status !== 200) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.message || 'Error en el inicio de sesión');
      } else {
        toastifyRef.current.notify(
          'custom', 
          'Mensaje enviado, revisa tu correo para terminar tu solicitud de cambio de contraseña',
          <img src={iconMail} />
        );
        reset();
      }
    } catch (error) {
      setServerError(error.message);
    }
  };

  return (
    <>
      <SEO
        title={'Checo Cards | Recuperar contraseña'}
        description={'Conviértete en el más rápido explorador y colecciona todas las tarjetas de Checo Pérez.'} />
      <Layout id='recover-pass' classes='pt-main pb-18 relative forms'>
        <div className='bg cnt-h col-1'>
          <picture>
            <source srcSet={bg.desk_avif + ' 1x, ' + bg.desk2_avif + ' 2x'}
              type="image/avif" />
            <source srcSet={bg.desk_webp + ' 1x, ' + bg.desk2_webp + ' 2x'}
              type="image/webp" />
            <img className='col-1'
              srcSet={bg.desk + ' 1x, ' + bg.desk2 + ' 2x'}
              src={bg.desk}
              alt=''
              width={375}
              height={420} />
          </picture>
        </div>
        <div className='px-container mb-24 relative'>
          <h1 className='text-center text-venera mb-12'>Restablecer contraseña</h1>
          <div className='px-24'>
            <p className='text-center'>Para restablecer tu contraseña, <br />ingresa el correo electrónico a continuación</p>
          </div>
          <div className='form mt-18 px-12'>
            <form
              onSubmit={handleSubmit(onRecoverPassword)}
              className='relative'
            >
              <div className='input-group'>
                <div className='flex flex-start'>
                  <div className='relative col-1'>
                    <input
                      id='email'
                      type="email"
                      name='email'
                      {...register('email', {
                        required: 'El correo es obligatorio',
                        pattern: {
                          value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                          message: 'Correo no válido'
                        }
                      })} />
                    {errors.email && <span className="error-message">{errors.email.message}</span>}
                  </div>
                  <label htmlFor="email">Correo</label>
                </div>
              </div>
              <button className='btns btns-blue mt-24'>
                <span>Restablecer contraseña</span>
              </button>
              {serverError && <div className="error-message text-center my-12">{serverError}</div>}
              <Toastify ref={toastifyRef} />
            </form>
            <div className='text-center my-18'>
              <p>Regresar a <Link to="/login"
                className="text-blue"
              ><u>Iniciar sesión</u></Link></p>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export { RecoverPassword }
