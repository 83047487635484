import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const ImageCard = ( {card, totalCards, activePage} ) => {
  const [loaded, setLoaded] = useState('show');

  return (
    <Link
      to={'/mi-album/card/' + card.card._id}
      state={{
        card,
        'totalCards': totalCards,
        'albumPage': activePage
      }}
      className={`relative`}>
      <picture>
        <source srcSet={`${ card.card.imagen }-sm.avif 1x, ${ card.card.imagen }.avif 2x`}
          type="image/avif" media='(max-width: 768px)' />
        <source srcSet={`${ card.card.imagen }-sm.webp 1x, ${ card.card.imagen }.webp 2x`}
          type="image/webp" media='(max-width: 768px)' />
        <source srcSet={`${ card.card.imagen }.avif 1x, ${ card.card.imagen }@2.avif 2x`}
          type="image/avif" />
        <source srcSet={`${ card.card.imagen }.webp 1x, ${ card.card.imagen }@2.webp 2x`}
          type="image/webp" />
        <img className='col-1'
          srcSet={`${ card.card.imagen }.jpg 1x, ${ card.card.imagen }@2.jpg 2x`}
          src={`${ card.card.imagen }.jpg`}
          onLoad={() => setLoaded('')}
          alt="" width={640} height={960} />
      </picture>
      <span className={`cnt card-animation ${loaded}`}>
        <span className='line top'></span>
        <span className='line bottom'></span>
      </span>
    </Link>
  );
}

export {ImageCard};