const UserService = {
  async validToken() {
    const token = localStorage.getItem('CHECO_TOKEN');
    if (token) {
      const url = new URL(process.env.REACT_APP_SERVER_URL + process.env.REACT_APP_ENDPOINT_VALID_TOKEN);

      try {
        const response = await fetch(url.toString(), {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'token': token
          }
        });

        const res = await response.json();
        if (response.status !== 200) {
          localStorage.removeItem('CHECO_TOKEN');
          localStorage.removeItem('CHECO_USER');
          return false;
        }
        return res.msg === "Token Valido";
      } catch (err) {
        localStorage.removeItem('CHECO_TOKEN');
        localStorage.removeItem('CHECO_USER');
        return false;
      }
    } else {
      return false;
    }
  }
};

export default UserService;