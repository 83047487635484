import React from 'react';
import { Helmet } from "react-helmet";
const SEO = ({ title, description }) =>  {

  return (
    <Helmet>
      <title>{title || ''}</title>
      <meta name='description' content={description || ''} />
      
      <meta property="og:url" content="https://checocards.com/"/>
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title || ''} />
      <meta property="og:description" content={description || ''} />
      <meta property="og:image" content='/assets/checo-cards.jpg'/>
      <meta property="og:locale" content="es_MX"/>
      <meta property="og:site_name" content={title || ''}/>
      
      <meta name="twitter:card" content="summary"/>
      <meta name="twitter:title" content={title || ''} />
      <meta name="twitter:description" content={description || ''} />
      <meta name="twitter:image" content='/assets/checo-cards.jpg'/>
      
    </Helmet>
  )
}

export default SEO;