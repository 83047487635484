import React from 'react'
import SEO from 'providers/HelmetProvider';
import Layout from 'components/routes/Layout';

function Terms() {
  return (
    <>
      <SEO
        title={'Checo Cards | Términos y condiciones'}
        description={'Conviértete en el más rápido explorador y colecciona todas las tarjetas de Checo Pérez.'} />

      <Layout id='home' classes='pt-main pb-24'>
        <section className='px-container'>
          <div className='mb-24 pb-6 px-12'>
            <h1 className='text-venera text-center'>Términos y condiciones</h1>
            <p className='text-center'>Ultima actualización: 07 de agosto de 2024</p>
          </div>
          <div className='px-12 pb-24'>
            <p className='pl-18 mt-24'>Radiomovil Dipsa, S.A. de C.V., con domicilio en Lago Zúrich No. 245, Edificio “Telcel”, Colonia
              Ampliación Granada, Miguel Hidalgo, C.P. 11529, en la Ciudad de México (en adelante “Telcel),
              pone a disposición del público en general los siguientes Términos y Condiciones para el uso de la
              experiencia Checo Cards (en adelante la “Experiencia”).</p>
            <p className='pl-18 mt-24' >POR FAVOR LEA ATENTAMENTE LOS SIGUIENTES TERMINOS DE USO ANTES DE INICIAR “LA
              EXPERIENCIA”. AL ACCEDER, DESCARGAR O UTILIZAR LA APLICACIÓN PARA DISPOSITIVOS
              MOVILES, EL SOFTWARE, O EL SITIO WEB, USTED ACEPTA HABER LEÍDO Y ACEPTADO ESTOS
              TERMINOS Y CONDICIONES. SI NO ESTÁ DE ACUERDO EN ALGÚN PUNTO, POR FAVOR
              ABSTÉNGASE DE SU USO Y PROCEDA CON SU DESINSTALACIÓN DE SUS EQUIPOS.</p>
            <p className='pl-18 mt-24'>EL USO DE “LA EXPERIENCIA” TAMBIEN ESTA REGIDA POR LA POLITICA DE PRIVACIDAD,
              DISPONIBLE EN: <a href="https://checocardstelcel.com/aviso-de-privacidad"><u>https://checocardstelcel.com/aviso-de-privacidad</u></a> POR LO QUE AL ACEPTAR
              CUMPLIR CON ESTOS TERMINOS Y CONDICIONES TAMBIÉN ACEPTA QUE HA LEIDO Y
              COMPRENDIDO EL MANEJO DE SUS DATOS PERSONALES E INFORMACIÓN OTORGADA PARA
              SU REGISTRO.</p>
            <p className='pl-18 mt-24'>ESTOS TERMINOS Y CODICIONES CONFORMAN EL ACUERDO ENTRE USTED Y TELCEL PARA
              EL USO DE “LA EXPERIENCIA” Y SERÁN VÁLIDAS PARA CUALQUIER CONTROVERSIA QUE SE
              PUDIERA LLEGAR A SUSCITAR.</p>
            <ol className='pl-18 mt-24'>
              <li>
                <h2 className='b'>DEFINICIONES.</h2>
                <br />
                <p><u>Administrador:</u> significa la persona moral Element Studios, S.C, designado por Radiomóvil Dipsa,
                  S.A. de C.V, para la administración y operación de la Experiencia.
                  <br />
                  <u>Cuenta:</u> perfil individual asociado al Usuario para su acceso a la Experiencia.
                  <br />
                  <u>Experiencia:</u> juego de realidad aumentada cuyo objetivo es recolectar y coleccionar diversas tarjetas
                  digitales con la imagen del piloto mexicano Sergio “Checo” Perez, a través de un código QR y el
                  uso de su dispositivo móvil.<br/>
                  <u>Incentivos:</u> recompensas o beneficios otorgados por Telcel, y que los participantes podrán adquirir
                  a través del canje de los puntos generados por la recolección de las tarjetas, las dinámicas o
                  mecanismos de intercambio, serán en su caso, comunicados por Telcel a través de la experiencia
                  o sus canales de comunicación digital.
                  <br />
                  <u>Perfil:</u> Sección asignada al Usuario, en donde podrá visualizar las tarjetas y puntos acumulados.
                  <br />
                  <u>Usuario:</u> persona física que se registra dentro de la plataforma para el uso de la Experiencia.
                </p>
                <br />
              </li>
              <li>
                <h2 className='b'>LA EXPERIENCIA.</h2>
                <br />
                <p>La Experiencia “Checo Cards” consiste en un juego web móvil de realidad aumentada cuyo objetivo
                  es recolectar y coleccionar diversas tarjetas digitales con la imagen del piloto mexicano Sergio
                  “Checo” Perez, a través de un código QR y el uso de su dispositivo móvil, las tarjetas estarán distribuidas en diversos centros de atención al cliente de Telcel y puntos emblemáticos de la
                  República mexicana.
                <br />
                  Telcel se reserva el derecho de suspender, modificar o cancelar el acceso a la Experiencia en cualquier momento y sin previo aviso.
                <br />
                </p>
                <br />
                <ol>
                  <li>
                    <div>
                      <h3 className='b'>Tarjetas Digitales.</h3>
                      <br />
                      <ul className='pl-18'>
                        <li><p>Las tarjetas digitales constituyen una categoría de contenido de la Experiencia, y por tanto el
                          Usuario no adquiere ningún derecho de propiedad sobre estas; Las tarjetas digitales tendrán un
                          valor en puntos asignados por Telcel, una vez que el Usuario cuente con más de 1 tarjeta y hasta
                          5, se habilitará dentro de su cuenta la posibilidad de intercambiarlas con otros usuarios.</p></li>
                        <li><p>El Usuario podrá tener hasta 5 tarjetas iguales (repetidas) con un máximo de 500 tarjetas en su
                          Perfil, considerando que la totalidad de la colección es de 100 tarjetas.</p></li>
                        <li><p>LAS TARJETAS NO SE PUEDEN VENDER, TRANSFERIR NI INTERCAMBIAR A CAMBIO DE
                          DINERO, NI POR NINGÚN OTRO TIPO DE CONTRAPRESTACIÓN, ESTAS TARJETAS NO TIENEN
                          NINGÚN VALOR MONETARIO.</p>
                          <br /></li>
                      </ul>
                    </div>
                  </li>
                </ol>
              </li>
              <li>
                <h2 className='b'>ACCESO.</h2>
                <br />
                <p>Para tener acceso a la Experiencia, el Usuario necesitará crear una Cuenta a través del sitio web
                  <a href="www.checocardstelcel.com"><u>www.checocardstelcel.com</u></a>, ingresando su nombre, correo electrónico y número de celular.</p>
                <p>Para poder crear una Cuenta, el Usuario deberá:</p>
                <br />
                <ul className='letter pl-18'> 
                  <li>a) Ser una persona física con capacidad legal para suscribir los términos de este servicio;</li>
                  <br />
                  <li>b) Tener como lugar de residencia la República Mexicana;</li>
                  <br />
                  <li>c) Tener como mínimo 18 años cumplidos;</li>
                  <br />
                  <li>d) Contar con un dispositivo con conexión o acceso a internet.</li>
                  <br />
                </ul>
                <p>Para el uso de su Cuenta, el Usuario deberá generar una contraseña compuesta de un mínimo de 6 caracteres, 1 mayúscula, 1 carácter especial o número y sin espacios, siendo el único responsable de su administración y seguridad.</p>
                <br />
                <p>Telcel no será responsable de ninguna pérdida o daño que el Usuario pueda sufrir como
                  consecuencia de que una persona no autorizada acceda a su Cuenta y/o utilice sus datos de
                  acceso, por lo que cualquier mal uso que se realice en su nombre, se presumirá realizado por este.</p>
                <p>El Usuario acepta que podemos usar la dirección de correo electrónico registrada en su Cuenta
                  para enviarle información sobre la Experiencia y su uso. Si la dirección de correo electrónico cambia,
                  el Usuario será el único responsable de actualizar la información de su Cuenta.</p>
                <br />
              </li>

              <li>
                <h2 className='b'>ACEPTACIÓN DE LOS TÉRMINOS.</h2>
                <br />
                <p>Con su registro y la creación de Cuenta, el Usuario manifiesta que (i) que toda la información
                  ingresada con motivo de su registro es verdadera, (ii) asume la responsabilidad por la actividad de
                  su Cuenta y la seguridad en sus credenciales de acceso, (iii) asume toda la responsabilidad de
                  todas las acciones que realice con motivo de la utilización de la Experiencia, las cuales son
                  ejecutadas por su cuenta y de manera voluntaria y (iv) se obliga a no interferir en el uso de la
                  Experiencia por parte de otros usuarios.</p>
                <br />
                <p><i>Telcel a través de su Administrador, se reserva el derecho de eliminar o desactivar cualquier registro
                  o Cuenta que presente irregularidades al momento de demostrar su identidad, así como a los
                  Usuarios que se detecten o aparenten ser “cazapremios”, “hackers” o que estén participando con
                  una o más Cuentas asociadas a una misma identidad. Tampoco podrán hacer uso de la experiencia
                  bots, cuentas automatizadas, cuentas inactivas o cuentas pertenecientes a personas morales o de
                  empresa.</i></p>
                  <br />
              </li>
              <li>
                <h2 className='b'>PERMISOS.</h2>
                <br />
                <p>Para poder hacer uso de la Experiencia, el Usuario reconoce y acepta que deberá dar acceso y
                  autorizar las funciones de cámara y ubicación en su dispositivo móvil. El Usuario podrá desactivar
                  dichas funciones y autorizaciones en cualquier momento, en el entendido, que el funcionamiento de
                  la Experiencia se verá comprometido o limitado.</p>
                <br />
              </li>
              <li>
                <h2 className='b'>REGLAS DE USO.</h2>
                <br />
                <p>Por los presentes términos y condiciones, se otorga al Usuario el derecho de usar la Experiencia de
                  acuerdo con estos lineamientos, a título personal, para propósitos no comerciales, por lo que por
                  ningún motivo el Usuario podrá:</p>
                <br />
                <ol>
                  <li>
                    <p>Modificar o alterar en todo o en parte cualquier componente o material del contenido de la Experiencia;</p>
                  </li>
                  <br />
                  <li>
                    <p>Modificar o crear derivados basados en la Experiencia;</p>
                  </li>
                  <br />
                  <li>
                    <p>Utilizar el contenido de la Experiencia de cualquier forma distinta a la autorizada, que se
                      considere una forma de explotación para propósitos comerciales o que viole derechos de
                      propiedad intelectual;</p>
                  </li>
                  <br />
                  <li>
                    <p>
                      Utilizar la Experiencia con fines comerciales, como venta o actividades de promoción o
                      publicitarias de bienes o servicios;
                    </p>
                  </li>
                  <br />
                  <li>
                    <p>
                      Publicar o hacer uso de la Experiencia para intercambiar o transmitir cualquier tipo de
                      contenido que pudiera considerarse ofensivo, obsceno o intimidatorio hacia la comunidad
                      de usuarios, que pudiera contener virus o malware, Telcel no será responsable respecto del
                      contenido o comunicaciones entre los usuarios;
                    </p>
                  </li>
                  <br />
                  <li>
                    <p>
                      No ingresará ni utilizará ningún contenido a través de la Experiencia que viole o infrinja
                      derechos de propiedad intelectual o derechos de privacidad de terceros
                    </p>
                  </li>
                  <br />
                  <li>
                    <p>
                      Se abstendrá de allanar, o de cualquier modo entrar o intentar acceder a cualquier lugar o
                      inmueble en el que no tenga derecho a estar, así como de incurrir en cualquier actividad
                      que pueda provocar, lesiones personales, daños materiales, molestias o responsabilidad
                      de cualquier tipo sobre su persona o cualquier tercero;
                    </p>
                  </li>
                  <br />
                </ol>
                <br />
                <p>Telcel podrá limitar, suspender, eliminar y no permitir un nuevo registro a la Experiencia, si el Usuario
                  no cumple con los términos aquí estipulados, o por cualquier uso inadecuado, o ponga en riesgo la
                  operación de la Experiencia sin necesidad alguna de notificación. Como consecuencia de la
                  cancelación el Usuario perderá su Cuenta, las tarjetas y cualquier recompensa o puntos generados
                  por su uso.</p>
                  <br />
              </li>
              <li>
                <h2 className='b'>INTERACCIÓN CON OTROS USUARIOS Y TERCEROS.</h2>
                <br />
                <p>El Usuario acepta que, derivado del uso de la Experiencia, podrá mantener contacto con otros
                  usuarios y personas de manera real; Telcel no puede garantizar la identidad de ninguno de los
                  usuarios con los que usted interactúe durante la Experiencia ni la autenticidad de los datos
                  proporcionados por estos.</p>
                <br />
                <p>
                  Por lo anterior, el Usuario asume todos los riesgos relativos al uso de la Experiencia y de
                  interacciones con otros usuarios y con otras personas con las que se pueda comunicar o interactuar
                  como resultado del uso de la Experiencia. El Usuario acepta que deberá tomar precauciones
                  razonables en todas sus comunicaciones e interacciones con otros usuarios y terceros con los que
                  se comunique, en particular si decide encontrarse con dichas personas físicamente.
                </p>
                <br />
              </li>
              <li>
                <h2 className='b'>SERVICIOS DE TERCEROS.</h2>
                <br />
                <p>La Experiencia puede contener enlaces a páginas o servicios de terceros que no son propiedad o
                  responsabilidad de Telcel, por lo que Telcel no será responsable del contenido, operación,
                  disponibilidad o calidad de estos.</p>
                <br />
                <p>Estos servicios de terceros pueden interferir o afectar el uso de la Experiencia, el Usuario renuncia
                  en este acto y exonera a Telcel y a cualquier otra parte que participe en la creación o la ejecución
                  de la Experiencia, frente a cualesquiera reclamaciones, demandas, daños y perjuicios, pérdidas,
                  gastos o responsabilidades que se deriven o que puedan surgir con ocasión de, o que se refieran
                  en cualquier modo a, dichos servicios de terceros.</p>
                <br />
              </li>
              <li>
                <h2 className='b'>PROPIEDAD INTELECTUAL Y MARCAS REGISTRADAS.</h2>
                <br />
                <p>La Experiencia, sus signos distintivos, código fuente, y cualquier otro derecho de propiedad
                  intelectual, se encuentran protegidos en favor de Telcel y sus socios comerciales, por las leyes
                  mexicanas, internacionales y tratados en materia de propiedad intelectual.</p>
                <br />
                <p>El uso de la Experiencia no concede al Usuario ningún Derecho de Propiedad Intelectual sobre esta,
                  su contenido o información. En virtud de lo anterior el Usuario reconoce que no podrá divulgar,
                  publicar, reproducir, distribuir, transformar o disponer de ningún modo de la Experiencia, las tarjetas,
                  o cualquier material incorporado a estos.</p>
                <br />
                <p>El Usuario se obliga a no duplicar, copiar, descargar, capturar, almacenar, distribuir, o de cualquier
                  otra forma reproducir la Propiedad Industrial e Intelectual de la Experiencia, elementos visuales de
                  diseño o conceptos. En consecuencia, el Cliente sólo podrá capturar y/o almacenar la información
                  utilizando las herramientas que se encuentran disponibles a tal fin en la Experiencia.</p>
                  <br />
                  <ol>
                    <li>
                      <div>
                        <h3 className='b'>Marcas.</h3>
                        <br />
                      <p>Todas las marcas que aparecen dentro de la Experiencia y/o el Sitio Web son propiedad de
                        Radiomóvil Dipsa, S.A. de C.V., o bien cuenta con los permisos, autorizaciones o licencias
                        necesarias para su uso; Por lo anterior, el Usuario acepta y reconoce que nada en estos Términos
                        y Condiciones de uso debe interpretarse como una concesión, licencia o derecho sobre las marcas
                        de la Experiencia, ni lo legitima de forma alguna para utilizar ni explotar, de forma enunciativa mas
                        no limitativa a las marcas comerciales, logos, diseños, imagen comercial y demás conceptos, por
                        lo que no podrá divulgar ni reproducir el contenido de la Experiencia, aún y cuando sea sin fines de
                        lucro.</p>
                        <br />
                      <p>El Usuario reconoce que lo establecido en la presente Cláusula será aplicable a los derechos de
                        terceros respecto al contenido o información presentada o vinculada en la Experiencia.</p>
                        <br />
                      </div>
                    </li>
                    <li>
                      <div>
                        <h3 className='b'>Propia Imagen Sergio “Checo” Pérez.</h3>
                        <br />
                        <p>
                        Las Partes acuerdan que Telcel en su calidad de patrocinador oficial del piloto mexicano Sergio
                        “Checo” Pérez, cuenta con las licencias, permisos y autorizaciones necesarios y convenientes para
                        para el uso de sus derechos de propia imagen para la operación de la Experiencia.
                        </p>
                        <p>
                        Telcel, Sergio “Checo” Pérez, “Red Bull” Red Bull Racing y sus logotipos, son marcas registradas,
                        todas las marcas que no son propiedad de Telcel y aparecen dentro de la Experiencia son propiedad
                        de sus respectivos titulares, quienes pueden o no estar directa o indirectamente vinculados,
                        relacionados o patrocinados por Telcel.
                        </p>
                        <br />
                        <p>
                        SE PROHÍBE EXPRESAMENTE IMPRIMIR O REPRODUCIR ELECTRÓNICAMENTE CUALQUIER
                        CONTENIDO O GRÁFICO EN SU TOTALIDAD O EN PARTE DE LA EXPERIENCIA PARA
                        CUALQUIER PROPÓSITO AÚN Y CUANDO SEA SIN FINES DE LUCRO.
                        </p>
                        <br />
                        <p>CUALQUIER TENTATIVA O ACTIVIDAD VIOLATORIA O CONTRARIA A LOS DERECHOS DE
                        PROPIEDAD INTELECTUAL (INCLUIDOS LOS DE PROPIA IMAGEN, Y/O A LAS PROHIBICIONES
                        ESTIPULADAS EN ESTA CLÁUSULA, HARÁN ACREEDOR A SU RESPONSABLE DE LAS
                        ACCIONES LEGALES APLICABLES, INCLUYENDO SIN LIMITE LA INDEMNIZACIÓN POR DAÑOS
                        Y PERJUICIOS, SIN PERJUICIO DE LAS ACCIONES PENALES Y ADMINISTRATIVAS
                        CONDUCENTES.</p>
                        <br />
                      </div>
                    </li>
                  </ol>
              </li>
              <li>
                <h2 className='b'>RECOMPENSAS.</h2>
                <br />
                <p>Los Usuarios deberán de cumplir en todo momento con los presentes Términos y Condiciones para
                  hacer uso de la Experiencia y poder participar en las dinámicas y beneficios relacionados con el
                  uso de esta y en su caso, ser acreedores de alguna de las recompensas.</p>
                <br />
                <p>El otorgamiento de las recompensas siempre estará sujeto a su disponibilidad, por lo que Telcel no se obliga a garantizar su entrega.</p>
                <br />
                <p>El Usuario no podrá comercializar o lucrar con las recompensas obtenidas con motivo de su
                  participación en las dinámicas y promociones de la Experiencia. En caso de que Telcel sospechare
                  que el Usuario llevó a cabo alguna de estas actividades, el Usuario podrá ser descalificado sin
                  responsabilidad para Telcel. Las Recompensas son personales y no podrán ser transferidos a
                  ningún otro Usuario o tercero, por lo que el Usuario acepta que, en caso de resultar favorecido,
                  deberá recibir personalmente su recompensa.</p>
                <br />
                <p>No son elegibles para resultar como beneficiarios de alguna de las recompensas los empleados,
                  colaboradores de las empresas desarrolladoras, agencias o terceros incluyendo sin limitar, sus
                  controladoras, filiales y/o subsidiaras, sucursales, que estén relacionadas con la Experiencia.
                  </p>
                <br />
              </li>
              <li>
                <h2 className='b'>EXCLUSIÓN DE GARANTÍAS.</h2>
                <br />
                <p>Como cualquier aplicación informática, la Experiencia es falible y estará en proceso permanente de mejora, Telcel no garantiza que la Experiencia se encuentre libre de errores y se facilita "tal cual es", sin garantía de ninguna clase.</p>
                <br />
                <p>Telcel no asumirá ninguna responsabilidad respecto de cualquier inconveniente o daño que pudiera
                  experimentar en sus dispositivos electrónicos (hardware/software) utilizados para el uso de la
                  Experiencia, ni de las posibles consecuencias derivadas de su uso de manera indebida o
                  fraudulenta, así como de cualquier gasto o cargo realizado por su compañía telefónica por el servicio
                  de internet.</p>
                <br />
              </li>
              <li>
                <h2 className='b'>INDEMNIZACIÓN.</h2>
                <br />
                <p>El Usuario manifiesta que comprende que cualquier intento de alterar, modificar, reproducir,
                  deliberadamente la Experiencia y sus contenidos, constituye una violación a las leyes de carácter
                  civil y penal; razón por la cual, en caso de presentarse dicho supuesto, Telcel se reserva el derecho
                  de reclamarle la indemnización por daños y perjuicios ante las autoridades correspondientes, así
                  como a interponer las acciones legales que juzgue convenientes para la defensa de sus derechos
                  e intereses.</p>
                <br />
              </li>
              <li>
                <h2 className='b'>LEYES APLICABLES.</h2>
                <br />
                <p>Para la interpretación y cumplimiento de los presentes Términos y Condiciones, así como cualquier
                  contingencia jurídica que nazca en relación con los mismos, El Usuario expresamente se somete a
                  la jurisdicción de los tribunales competentes de la Ciudad de México, así como a las leyes aplicables
                  en dicha jurisdicción.</p>
                <br />
                <p>
                  Si cualquier disposición de estos Términos y Condiciones fuera declarada ilícita, nula, ilegal, inválida
                  o inejecutable (en todo o en parte), entonces tal disposición será considerada independiente y
                  separable de los Términos y Condiciones y no afectará la ejecutabilidad de las restantes
                  disposiciones, las cuales conservarán plena vigencia y validez.
                </p>
                <br />
              </li>
              <li>
                <h2 className='b'>DUDAS, QUEJAS Y ACLARACIONES.</h2>
                <br />
                <p>Para la difusión de información relacionada con la Experiencia, los únicos medios autorizados por Telcel son: <a href='https://holatelcel.com/'><u>https://holatelcel.com/;</u></a> <a href='https://web.facebook.com/Telcel;'><u>https://web.facebook.com/Telcel</u></a> <a href='https://x.com/Telcel'><u>https://x.com/Telcel</u></a></p>
                <br />
              </li>
            </ol>
          </div>
        </section>
      </Layout>
    </>
  )
}

export { Terms }
