import React, { useImperativeHandle, forwardRef } from 'react';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './toastify.scss';
import closeButton from '../../assets/icons/i-close.svg';
import { useIsMobile } from 'utils/isMobile';

const CloseButton = () => <img className='Toastify__close-button' src={closeButton} alt="Close" />;

export const Toastify = forwardRef((props, ref) => {

  const isMobile = useIsMobile(); 

  useImperativeHandle(ref, () => ({
    notify(type, text, icon) {
      const options = {
        position: isMobile ? "bottom-center" : "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: false,
        theme: "light",
        icon: icon || null,
        transition: Slide,
        closeButton: <CloseButton />
      };
      switch (type) {
        case 'success':
          toast.success(text, options);
          break;
        case 'error':
          toast.error(text, options);
          break;
        case 'custom':
          toast(text, options);
          break;
        default:
          toast.info(text, options);
          break;
      }
    }
  }));

  return (
    <ToastContainer />
  );
});
