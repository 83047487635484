import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { ax } from 'providers/LoaderProvider';
import SEO from 'providers/HelmetProvider';
import Layout from 'components/routes/Layout';

import UserService from 'services/userService.service';
import changeNameImages from 'utils/changeNameImages';
import { AFrameScene } from 'lib/aframe-components'
import { tapPlaceComponent } from 'lib/tap-place';
import html from './assets/cube.html';
import { useUserContext } from 'providers/UserProvider';
import './cards.scss';
import melody from 'assets/audio/confirm.mp3'

const Cards = () => {
  const saveCardImg = changeNameImages('/assets/images/game/save-card.png');
  const location = useLocation();
  const data = location.state;
  const { user } = useUserContext(); 
  const [nexStep, setNexStep] = useState(false);
  const [error, setError] = useState({
    message: '',
    error_type: 0
  });
  const audio = new Audio(melody);
  
  const saveCard = async () => {
    const dataForm = {
      userid : user.uid,
      cardid : data.cardID,
      locid : data.locationID
    };
    const headerConfig = {
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'token': user.token
      },
    };
    ax
      .post(
        `${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_ENDPOINT_SAVE_CARD}`,
        dataForm, headerConfig)
      .then(async (res) => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error(errorResponse.message || 'Ocurrió un error inesperado');
        } else {
          setNexStep(true);
        }
      })
      .catch((err) => {
        setError({
          message: err.response.data.msg || 'Ocurrió un error inesperado',
          error_type: 2
        })
      })
    audio.play();
  }

  const validateToken = async () => {
    try {
      const res = await UserService.validToken();
      if (!res) {
        setError({
          message: 'Tu sesión ha expirado, intenta loguearte de nuevo.',
          error_type: 1
        })
      }
    } catch (err) {
      navigate('/login')
    }
  }

  useEffect(() => {
    if (data && data.cardAnimate){
      localStorage.setItem('3d-card', data.cardAnimate.imagen);
    } else {
      console.error('Los datos son inconsistentes.')
      setError({
        message: 'Ocurrió un error inesperado.',
        error_type: 2
      })
    }
    validateToken();
  }, [])
  
  return (
    <>
      <SEO 
        title={'Checo Cards | Tarjetas'}
        description={'Conviértete en el más rápido explorador y colecciona todas las tarjetas de Checo Pérez.'} 
      />
      <Layout id='game' classes='relative'>
        <section className='card-content full'>
          {user &&
            <>
              <AFrameScene
                sceneHtml={html}
                components={[
                  { name: 'tap-place', val: tapPlaceComponent },
                ]} />
              <p className={'h1 text-center text-venera text-white cnt-h show-animation'}
              >Tap para <br />descubrir tarjeta</p>
            </>}

          <button className={'save btns cnt-h show-animation'}
            type='button'
            onClick={saveCard}>
            <span>Guardar tarjeta</span>
          </button>
        </section>

        <section className={'success cnt full flex flex-center flex-column' + (nexStep ? ' active' : '')}>
          <div className='content relative col-1'>
            <p className='h1 text-center text-venera text-white'>Tarjeta <br />guardada</p>
            <picture>
              <source srcSet={saveCardImg.desk_avif + ' 1x, ' + saveCardImg.desk2_avif + ' 2x'}
                type="image/avif" />
              <source srcSet={saveCardImg.desk_webp + ' 1x, ' + saveCardImg.desk2_webp + ' 2x'}
                type="image/webp" />
              <img className='col-2'
                srcSet={saveCardImg.desk + ' 1x, ' + saveCardImg.desk2 + ' 2x'}
                src={saveCardImg.desk}
                alt=''
                width={91}
                height={83} />
            </picture>
            <Link className='btns btns-red mx-auto'
              to={'/mi-album/card/' + (data && data.cardID ? data.cardID : '')}
              state={{
                card: data
              }}>
              <span>Aceptar y ver tarjeta</span>
            </Link>
          </div>
        </section>

        <section id='error-location' className='errors full cnt flex flex-center'>
          <div className='px-container relative'>
            <img src="/assets/images/global/i-information.svg"
              className='i-info mx-auto'
              alt="" width={100} height={100} />
            <div className='description text-center mx-auto'>
              <p className='text-white'>Aún no estás dentro del rango, acércate un poco más a la ubicación exacta.</p>
            </div>
            <div>
              <Link className='btns btns-red mx-auto'
                to={'/explorar'}>
                <span>Regresar al mapa</span>
              </Link>
            </div>
          </div>
        </section>

        <section id='error' className={(error.error_type === 0 ? '' : 'show ') + 'errors full cnt flex flex-center'}>
          <div className='px-container relative'>
            <img src="/assets/images/global/i-information.svg"
              className='i-info mx-auto'
              alt="" width={100} height={100} />
            <div className='description text-center mx-auto'>
              <p className='text-white'>{error.message}</p>
            </div>
            <div>
              {error.error_type === 1 &&
                <Link className='btns btns-red mx-auto'
                  to={'/login'}>
                  <span>Iniciar sesión</span>
                </Link>}
              {error.error_type === 2 &&
                <Link className='btns btns-red mx-auto'
                  to={'/explorar'}>
                  <span>Regresar al mapa</span>
                </Link>}
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export { Cards }
