import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
import changeNameImages from 'utils/changeNameImages';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';



const FirstTime = () => {

  const step1 = changeNameImages('/assets/images/first-time/step-1.jpg');
  const step2 = changeNameImages('/assets/images/first-time/step-2.jpg');
  const step3 = changeNameImages('/assets/images/first-time/step-3.jpg');

  const closeModal = () => {
    localStorage.removeItem('CHECO_FIRST_TIME');
    const modal = document.getElementById('modal-first-time');
    modal.classList.add('d-none');
  }

  return (
    <section id='modal-first-time' className='modal full cnt'>

      <div 
        className='close-btn'
        onClick={() => closeModal()}
      ><span className='close-icon cnt'></span></div>

      <Swiper
        modules={[Pagination, Navigation]}
        spaceBetween={50}
        slidesPerView={1}
        centeredSlides={true}
        pagination={{
          dynamicBullets: true,
        }}
        navigation={true}
      >
        <SwiperSlide>
          <picture>
            <source srcSet={step1.desk_avif + ' 1x, ' + step1.desk2_avif + ' 2x'}
              type="image/avif" />
            <source srcSet={step1.desk_webp + ' 1x, ' + step1.desk2_webp + ' 2x'}
              type="image/webp" />
            <img className='img-step mx-auto col-1 relative'
              srcSet={step1.desk + ' 1x, ' + step1.desk2 + ' 2x'}
              src={step1.desk}
              alt=''
              width={900}
              height={1600}
            />
          </picture>
        </SwiperSlide>
        <SwiperSlide>
          <picture>
            <source srcSet={step2.desk_avif + ' 1x, ' + step2.desk2_avif + ' 2x'}
              type="image/avif" />
            <source srcSet={step2.desk_webp + ' 1x, ' + step2.desk2_webp + ' 2x'}
              type="image/webp" />
            <img className='img-step mx-auto col-1 relative'
              srcSet={step2.desk + ' 1x, ' + step2.desk2 + ' 2x'}
              src={step2.desk}
              alt=''
              width={900}
              height={1600}
            />
          </picture>
        </SwiperSlide>
        <SwiperSlide>
          <picture>
            <source srcSet={step3.desk_avif + ' 1x, ' + step3.desk2_avif + ' 2x'}
              type="image/avif" />
            <source srcSet={step3.desk_webp + ' 1x, ' + step3.desk2_webp + ' 2x'}
              type="image/webp" />
            <img className='img-step mx-auto col-1 relative'
              srcSet={step3.desk + ' 1x, ' + step3.desk2 + ' 2x'}
              src={step3.desk}
              alt=''
              width={900}
              height={1600}
            />
          </picture>
        </SwiperSlide>
      </Swiper>
    </section>
  )
}

export { FirstTime }
