const tapPlaceComponent = {
  init() {
    const html = document.getElementsByTagName('html')[0];
    const assetsContent = document.getElementById('assets');
    const card = document.createElement('a-asset-item');
    const url3D = localStorage.getItem('3d-card');

    card.setAttribute('src', url3D);
    card.id = 'myCard';
    assetsContent.appendChild(card);

    const ground = document.getElementById('ground');
    let firstTap = true;
    ground.addEventListener('click', (event) => {
      if (!firstTap) return;
      firstTap = false;
      html.classList.add('show-card');
      const touchPoint = event.detail.intersection.point;
      const newElement = document.createElement('a-entity')

      newElement.setAttribute('visible', false);
      newElement.setAttribute('position', touchPoint);
      newElement.setAttribute('scale', '.0001 .0001 .0001');
      newElement.setAttribute('shadow', {
        receive: false,
      })
      newElement.setAttribute('gltf-model', '#myCard')
      this.el.sceneEl.appendChild(newElement)
      newElement.addEventListener('model-loaded', () => {
        newElement.setAttribute('visible', 'true')
        newElement.setAttribute('animation', {
          property: 'scale',
          to: '2 2 2',
          easing: 'easeOutElastic',
          dur: 800,
        })
        newElement.setAttribute('animation__2', {
          property: 'rotation',
          to: '0 360 0',
          easing: 'linear',
          loop: true,
          dur: 10000
        })
      })
    });
  }
}

export {tapPlaceComponent}
