import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import { Album } from '../../views/album/album';
import { Cards } from '../../views/cards/cards';
import { DetailCard } from '../../views/detailCard/detailCard';
import { SaveCard } from '../../views/saveCard/saveCard';
import { Explore } from '../../views/explore/explore';
import { Home } from '../../views/home/home';
import { LogIn } from '../../views/login/login';
import { RecoverPassword } from '../../views/recoverPassword/recoverPassword';
import { Mechanics } from '../../views/mechanics/mechanics';
import { News } from '../../views/news/news';
import { DetailNew } from '../../views/detailNews/detailNews';
import { Register } from '../../views/register/register';
import { Legals } from '../../views/legals/legals';
import { Terms } from '../../views/legals/terms';
import { NotFound } from '../../views/notFound/notFound';
import { ChangePassword } from '../../views/changePassword/changePassword';

const RoutesWithAnimation = () => {
  const location = useLocation();

  return (
    <AnimatePresence
      mode='wait'
      onExitComplete={() => {
        if (typeof window !== 'undefined') {
          window.scrollTo({ top: 0, behavior: 'instant' })
        }
      }}
    >
      <Routes location={location} key={location.key}>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/login' element={<LogIn />} />
        <Route exact path='/cards' element={<Cards />} />
        <Route exact path='/restablecer-password' element={<RecoverPassword />} />
        <Route exact path='/mi-album' element={<Album />} />
        <Route exact path='/mi-album/card/:idCard' element={<DetailCard />} />
        <Route exact path='/aviso-de-privacidad' element={<Legals />} />
        <Route exact path='/terminos-y-condiciones' element={<Terms />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </AnimatePresence>
  );
}

export { RoutesWithAnimation };