import React from 'react';
import SEO from 'providers/HelmetProvider';
import Layout from 'components/routes/Layout';

function Legals() {
  return (
    <>
      <SEO
        title={'Checo Cards | Aviso de Privacidad'}
        description={'Conviértete en el más rápido explorador y colecciona todas las tarjetas de Checo Pérez.'} />
      <Layout id='home' classes='pt-main pb-24'>
        <section className='px-container'>
          <div className='mb-24 pb-6 px-12'>
            <h1 className='text-venera text-center'>Aviso de Privacidad Actividad “Checo Cards”</h1>
          </div>
          <div className='px-12 pb-24'>
            <h2 className='b'>Identidad y domicilio del Responsable</h2>
            <br />
            <p>El Responsable del tratamiento de sus datos personales es la persona moral Radiomóvil Dipsa, S.A. de C.V. (en lo sucesivo “Telcel”) con domicilio para oír y recibir notificaciones el ubicado en Lago Zúrich 245, Plaza Carso, Edificio Telcel, Colonia Ampliación Granada, Alcaldía Miguel Hidalgo, C.P. 11529, Ciudad de México, México.</p>
            <br />
            <p>Usted puede consultar el presente Aviso de Privacidad en el momento de su registro y en el sitio www.checocards.com (en lo sucesivo, el “Sitio”). </p>
            <br />
            <h2 className='b'>Categorías de datos personales sujetas al tratamiento</h2>
            <br />
            <p>Para cumplir con las finalidades del tratamiento señaladas en el presente aviso de privacidad, es necesario recabar y dar tratamiento a las siguientes categorías de Datos Personales:</p>
            <br />
            <ul className='pl-18'>
              <li>
                <p>Datos de identificación (incluyendo datos de inicio de sesión en plataformas de terceros).</p>
              </li>
              <li>
                <p>
                  Datos de contacto.
                </p>
              </li>
              <li>
                <p>
                  Datos de ubicación.
                </p>
              </li>
              <li>
                <p>
                  Datos de localización e información técnica del dispositivo que usted utilice para participar en la experiencia “Checo Cards”. Para ello, será necesario que usted otorgue los permisos correspondientes al navegar desde su dispositivo móvil.
                </p>
              </li>
              <li>
                <p>
                  Información relacionada con sus actividades acciones y logros dentro de la plataforma y de la aplicación.
                </p>
              </li>
            </ul>
            <br />
            <p>Le informamos que para cumplir con lo establecido en el presente Aviso de Privacidad no se recabarán ni tratarán datos personales considerados como sensibles por la normatividad.</p>
            <br />
            <p>El presente Aviso de Privacidad le ayudará a entender qué datos recabamos, cómo los usamos y qué opciones tiene a su disposición cuando ingrese, se identifique, autentifique y/o use el sitio web <a href="www.checocards.com"><u>www.checocards.com</u></a> o participe en promociones y eventos que se desarrollen por “Checo Cards”.</p>
            <br />
            <h2 className='b'>Uso de los Datos Personales en el sitio web www.checocards.com</h2>
            <br />
            <p>El registro en el sitio web www.checocards.com es requisito para participar en la experiencia “Checo Cards”, por lo que deberá abrir una cuenta antes de poder participar en ella. En estos casos, recabaremos datos de identificación y contacto para administrar su acceso a la plataforma. Recabamos y usamos esta información para autenticar su identidad cuando abre una cuenta para asegurarnos de que puede y reúne los requisitos para participar en la experiencia.</p>
            <br />
            <p>Para ingresar a la plataforma usted puede iniciar sesión mediante el registro realizado en plataformas de terceros. En estos casos, el tratamiento de datos empleados para el inicio de sesión será responsabilidad de terceros a los que usted haya proporcionado sus datos y no de Telcel.</p>
            <br />
            <h2 className='b'>Datos personales de menores de edad</h2>
            <br />
            <p>Telcel no recaba información directamente de menores de edad por lo que, de ser necesario, dicha información deberá ser proporcionada, en su caso, por el padre, tutor y/o persona con patria potestad. En aquellos casos en los que Telcel identifique que una persona menor de edad ha proporcionado su información personal y/o que la persona que proporciona los datos personales no es la persona que ejerce la patria potestad de un menor de edad, para fines relacionados con la provisión de los Servicios, Telcel procederá a la cancelación de dicha información.</p>
            <br />
            <h2 className='b'>Finalidades primarias</h2>
            <br />
            <p>Telcel tratará sus datos personales antes descritos para realizar las siguientes finalidades primarias:</p>
            <br />
            <ul className='pl-24'>
              <li>
                <p>Para tener acceso a la plataforma de realidad aumentada “Checo Cards”, vinculada al mundo real y diseñada para que pueda interactuar dentro de ella.</p>
              </li>
              <li>
                <p>Para identificarle en la actividad “Checo Cards”.</p>
              </li>
              <li>
                <p>
                  Para realizar el registro de su participación la actividad “Checo Cards”.
                </p>
              </li>
              <li>
                <p>
                  Para realizar la actividad “Checo Cards”.
                </p>
              </li>
              <li>
                <p>
                  Contactarle para dar seguimiento a cuestiones relativas a su participación en la actividad “Checo Cards”.
                </p>
              </li>
              <li>
                <p>
                  Enviarle notificaciones y avisos a través de la actividad “Checo Cards”.
                </p>
              </li>
              <li>
                <p>
                  Para realizar actividades de análisis de la información y datos relacionados con su persona, de procesos automatizados y diversas técnicas y tecnologías de análisis de datos.
                </p>
              </li>
              <li>
                <p>
                  Analizar sus acciones y logros en el juego para mostrarle recompensas, promociones u otras ofertas relacionadas con la actividad “Checo Cards” que se adapten a su propia experiencia de usuario.
                </p>
              </li>
              <li>
                <p>
                  Organizar y realizar eventos relacionados directa o indirectamente con la actividad “Checo Cards”.
                </p>
              </li>
              <li>
                <p>
                  Atención y seguimiento de dudas, quejas y/o comentarios.
                </p>
              </li>
              <li>
                <p>
                  En su caso, realizar reportes y dar cumplimiento a políticas y procedimientos de carácter interno.
                </p>
              </li>
              <li>
                <p>
                  Dar cumplimiento a obligaciones legales previstas en la normatividad aplicable y atender requerimientos solicitados por las autoridades competentes, que, en el ámbito de su control y especialidad, lo requieran de manera fundada y motivada.
                </p>
              </li>
            </ul>
            <br />
            <h2 className='b'>Finalidades secundarias</h2>
            <br />
            <p>Si usted no se opone, Telcel tratará sus datos personales para llevar a cabo alguna o todas las siguientes finalidades secundarias:</p>
            <br />
            <ul className='pl-24'>
              <li><p>Envío de encuestas para mejorar el uso de nuestros Servicios y conocer su experiencia en la actividad “Checo Cards”.</p></li>
              <li><p>Para compartir novedades y noticias, bien dentro de la propia plataforma o bien por correo electrónico.</p></li>
            </ul>
            <br />
            <p>Si usted no desea que Telcel trate sus datos personales para la finalidad descritas en este apartado, por favor envíe un correo electrónico a la dirección <a href='mailto:datospersonales@telcel.com'><u>datospersonales@telcel.com</u></a> Usted podrá cambiar de opción en cualquier momento.</p>
            <br />
            <h2 className='b'>Transferencias</h2>
            <br />
            <p>Telcel para cumplir las finalidades necesarias anteriormente descritas u otras aquellas exigidas legalmente o por las autoridades competentes, sólo transferirá los datos necesarios en los casos legalmente previstos y para los cuales no es necesario su consentimiento.</p>
            <br />
            <h2 className='b'>Tiempo de Conservación de los Datos Personales</h2>
            <br />
            <p>Telcel conservará sus datos personales únicamente durante el tiempo que resulte necesario para prestarle los Servicios y mientras mantenga una base de legitimación que le permita, en términos de las leyes aplicables, dar tratamiento a sus datos personales. Una vez concluidos los períodos de bloqueo y retención aplicables, Telcel procederá a la supresión segura de sus datos. </p>
            <br />
            <h2 className='b'>Medios para ejercer Derechos ARCO</h2>
            <br />
            <p>Usted o su representante legal podrá ejercer cualquiera de los derechos de acceso, rectificación, cancelación u oposición (en lo sucesivo “Derechos ARCO”), así como revocar su consentimiento para el tratamiento de sus datos personales enviando un correo electrónico al Departamento de Datos Personales de Telcel a la dirección <a href="mailto:datospersonales@telcel.com"><u>datospersonales@telcel.com</u></a> donde se le atenderá en tiempo y forma.</p>
            <br />
            <p>Su petición deberá ser realizada a través de solicitud única para el ejercicio de derechos ARCO / revocación del consentimiento / uso y/o limitación de datos y dicha solicitud contiene todos los elementos para poder atender su solicitud conforme a la normatividad. Para que el Departamento de Datos Personales de Telcel pueda darle seguimiento a su solicitud, usted o su representante legal, deberá acreditar correctamente su identidad para lo que es necesario que complete todos los campos indicados en la solicitud y lo acompañe con copia de alguna de las identificaciones oficiales vigentes que se señalan en el mismo.</p>
            <br />
            <p>En caso de que la información proporcionada en la solicitud sea errónea o insuficiente, o bien, no se acompañen los documentos de acreditación correspondientes, el Departamento de Datos Personales de Telcel, dentro de los cinco (5) días hábiles siguientes a la recepción de la solicitud, podrá requerirle que aporte los elementos o documentos necesarios para dar trámite a la misma. Usted contará con diez (10) días hábiles para atender el requerimiento, contados a partir del día siguiente en que lo haya recibido. De no dar respuesta en dicho plazo, se tendrá por no presentada la solicitud correspondiente.</p>
            <br />
            <p>El Departamento de Datos Personales de Telcel le comunicará la determinación adoptada, en un plazo máximo de veinte (20) días hábiles contados desde la fecha en que se recibió la solicitud, a efecto de que, si resulta procedente, haga efectiva la misma dentro de los quince (15) días hábiles siguientes a que se comunique la respuesta. La respuesta se dará vía electrónica a la dirección de correo que se especifique en la solicitud.</p>
            <br />
            <h2 className='b'>Oposiciones y medios para limitar el uso o divulgación de sus datos personales</h2>
            <br />
            <p>Usted podrá limitar el uso o divulgación de sus datos personales enviando su solicitud al Departamento de Datos Personales de Telcel a la dirección de correo electrónico <a href='mailto:datospersonales@telcel.com'><u>datospersonales@telcel.com</u></a>. Los requisitos para acreditar su identidad, así como el procedimiento para atender su solicitud se regirán por los mismos criterios señalados en el apartado anterior.</p>
            <br />
            <p>En caso de que su solicitud resulte procedente, el Departamento de Datos Personales de Telcel lo registrará en el listado de exclusión propio de Telcel con el objeto de que usted deje de recibir nuestras promociones.</p>
            <br />
            <h2 className='b'>Cookies y otras tecnologías</h2>
            <br />
            <p>Le informamos que en nuestros sitios de internet y aplicaciones podemos hacer uso de cookies, web beacons y otras tecnologías de rastreo que nos permiten cumplir con las finalidades informadas en el presente Aviso de Privacidad. Todos nuestros sitios cuentan con una política de cookies aplicable que usted podrá consultar en el sitio: <a href="https://www.telcel.com/mundo_telcel/quienes-somos/corporativo/uso-cookies"><u>https://www.telcel.com/mundo_telcel/quienes-somos/corporativo/uso-cookies</u></a></p>
            <br />
            <h2 className='b'>Autoridad de protección de datos personales</h2>
            <br />
            <p>En caso de considerarlo necesario, le informamos que tiene el derecho de acudir ante el Instituto Nacional de Transparencia Acceso a la Información y Protección de Datos Personales (INAI) para hacer valer cualquier inconformidad relacionada con su derecho a la protección de datos personales.</p>
            <br />
            <h2 className='b'>Autoridad de protección de datos personales</h2>
            <br />
            <p>En caso de considerarlo necesario, le informamos que tiene el derecho de acudir ante el Instituto Nacional de Transparencia Acceso a la Información y Protección de Datos Personales (INAI) para hacer valer cualquier inconformidad relacionada con su derecho a la protección de datos personales.</p>
            <br />
            <h2 className='b'>Cambios en el Aviso de Privacidad</h2>
            <br />
            <p>Telcel se reserva el derecho, bajo su exclusiva discreción, de cambiar, modificar, agregar o eliminar partes del presente Aviso de Privacidad en cualquier momento. En tal caso, Telcel mantendrá su Aviso de Privacidad vigente en el sitio web <a href="https://checocards.com/registro"><u>https://checocards.com/registro</u></a></p>
            <br />
            <p>Le recomendamos visitar periódicamente esta página con la finalidad de informarse si ocurre algún cambio al presente.</p>
            <br />
            <h2 className='b'>Fecha de última actualización</h2>
            <br />
            <p>Junio 2024</p>
            <br />
          </div>
        </section>
        <section className='px-container py-12'>
          <div className='mb-24 pb-6 px-12 mt-24'>
            <h2 className='text-venera text-center h1'>AVISO DE PRIVACIDAD</h2>
          </div>
          <div className='px-12 pb-24'>
            <p>Element protegerá tu información en caso de proporcionarnos nombre, dirección, teléfono, correo electrónico, registro federal de causantes (RFC), Clave Única de Registro de Población (CURP), entre otros (en lo sucesivo, "Datos Personales". Al proporcionarlos, están consintiendo la manera en que se obtendrán, utilizarán, compartirán, almacenarán y tratarán tus Datos Personales en México de acuerdo al presente Aviso de Privacidad.
              <br /><br />
              Obtención de Datos Personales
              Los Datos Personales que serán proporcionados a Element a través de redes sociales, publicidad digital o sitios web serán utilizados para cumplir y mantener la relación comercial entre marca y el usuario, y puede incluir: procesamiento de pedidos, seguimiento a quejas y reclamaciones, premiaciones de dinámicas realizadas en digital, evaluación del trato a clientes, etc.
              <br /><br />
              Divulgación y Transmisión de Datos Personales
              Element no divulgará, almacenará, comercializará, rentará ni venderá tus Datos Personales de acuerdo a lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares y su Reglamento.
              <br /><br />
              Las operaciones en digital que no sean ejecutadas directamente por Element y que sean subcontratadas a otras empresas podrán tener acceso a los Datos Personales de forma confidencial y únicamente para desempeñar sus funciones.
              <br /><br />
              Los Datos Personales podrán ser transferidos a subsidiarias, filiales y afiliadas a Element en México o el extranjero para los fines anteriormente citados.
              <br /><br />
              Limitación del Uso y Divulgación de Datos Personales
              Los usuarios podrán limitar o revocar el permiso para el uso o divulgación de los Datos Personales en cualquier momento, al igual que ejercer sus derechos ARCO (Derechos de Acceso, Rectificación, Cancelación u Oposición de Datos Personales) bajo conformidad de la Ley Federal para la Protección de Datos Personales en Posesión de los Particulares. Los usuarios podrán contactar a Element en la dirección de correo electrónico info@element.com.mx para ello.
              <br /><br />
              Element puede modificar el presente Aviso de Privacidad en cualquier momento. Este podrá ser consultado en la página de Internet de Element en https://element.com.mx
            </p>
          </div>
        </section>

      </Layout>
    </>
  )
}

export { Legals }
