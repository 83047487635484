import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import changeNameImages from 'utils/changeNameImages';
import './header.scss';
import { useUserContext } from 'providers/UserProvider';


const Header = () => {
  const bg_menu = changeNameImages('/assets/images/global/bg-menu.jpg');

  const [openMenu, setOpenMenu] = useState(false);
  const { user, setUser } = useUserContext(); 

  let location = useLocation();

  const toggleMenu = (delay) => {
    if (delay) {
      setTimeout(() => {
        setOpenMenu(!openMenu);
      }, delay);
    } else {
      setOpenMenu(!openMenu);
    }
  };
  const closeMenu = () => {
    if (openMenu) {
      toggleMenu(100);
    }
  }

  const navigate = useNavigate();

  const logOut = () => {
    localStorage.clear();
    setUser(null);
    navigate('/');
  };

  return (
    <>
      <header id='header' className='p-6 col-1 cnt-h'>
        <nav className='p-6'>
          <div className='px-container flex flex-between col-1'>
            <Link to="/"
              className='logo py-6'
              onClick={closeMenu}>
              <img className='col-1'
                src={'/assets/images/global/logo-telcel-infinitum.svg'}
                alt='Telcel | Infinitum'
                width={144}
                height={14}
              />
            </Link>

            <button id='burger'
              type='button'
              className={openMenu ? 'active' : ''}
              onClick={toggleMenu}>
              <span></span>
            </button>
          </div>
        </nav>
      </header>

      <menu id='menu' className={'relative ' + (openMenu ? 'active' : '')}>
        <div className='bg cnt full'>
          <picture>
            <source srcSet={`${bg_menu.sm_avif} 1x, ${bg_menu.sm2_avif} 2x`}
              media="(max-width: 550px)" type="image/avif" />
            <source srcSet={`${bg_menu.sm_webp} 1x, ${bg_menu.sm2_webp} 2x`}
              media="(max-width: 550px)" type="image/webp" />
            <source srcSet={`${bg_menu.md_avif} 1x, ${bg_menu.md2_avif} 2x`}
              type="image/avif" />
            <source srcSet={`${bg_menu.md_webp} 1x, ${bg_menu.md2_webp} 2x`}
              type="image/webp" />
            <img className='full cnt'
              srcSet={`${bg_menu.desk} 1x, ${bg_menu.desk2} 2x`}
              src={bg_menu.desk}
              alt=''
              width={375}
              height={800}
            />
          </picture>
        </div>
        <div className='content-scroll py-24 px-container'>
          <ul className='relative mx-auto'>
            <li onClick={() => toggleMenu(100)}>
              <Link to="/"
                className={'link flex h1 text-white text-venera py-6 px-24 mb-6 ' + (location.pathname === '/' ? 'active' : '')}
              ><img src="/assets/images/global/menu-mark.svg"
                alt="" width={24} height={24}
                />Inicio</Link>
            </li>
            {
              user ? (
                <>
                  <li onClick={() => toggleMenu(100)}>
                    <Link to="mi-album"
                      className={'link flex h1 text-white text-venera py-6 px-24 mb-6 ' + (location.pathname.includes('/mi-album') ? 'active' : '')}
                    ><img src="/assets/images/global/menu-mark.svg"
                      alt="" width={24} height={24} />Mi álbum</Link>
                  </li>
                  <li onClick={() => { toggleMenu(100); logOut(); }}>
                    <Link to="/"
                      className={'link flex h2 text-white text-venera py-6 px-24 mb-6 mt-24'}
                    ><img src="/assets/images/global/menu-mark.svg"
                      alt="" width={24} height={24} />Cerrar sesión</Link>
                  </li>
                </>
              ) : (
                <>
                  <li onClick={() => toggleMenu(100)}>
                    <Link to="login"
                      className={'link flex h1 text-white text-venera py-6 px-24 mb-6 ' + (location.pathname === '/login' ? 'active' : '')}
                    ><img src="/assets/images/global/menu-mark.svg"
                      alt="" width={24} height={24} />LogIn</Link>
                  </li>
                </>
              )
            }
          </ul>
        </div>
      </menu>
    </>
  );
}

export { Header };
