import React, { useState } from 'react'
import melody from 'assets/audio/hit-spin.mp3'
import melodyReverse from 'assets/audio/hit-spin-reverse.mp3'

import changeNameImages from 'utils/changeNameImages';

const CardImage = ({card, category}) => {
  const [loaded, setLoaded] = useState('show');
  const [zoom, setZoom] = useState(false);

  const backImgCommon = changeNameImages('/assets/images/detail-card/BACK-01-NRMLS.jpg');
  const backImgSpecial = changeNameImages('/assets/images/detail-card/BACK-02-ESPECIALES.jpg');
  const backImgPremium = changeNameImages('/assets/images/detail-card/BACK-03-PODIO.jpg');
  const backImgHologram = changeNameImages('/assets/images/detail-card/BACK-04-HOLOGRAM.jpg');
  const audio = new Audio(melody);
  const audioReverse = new Audio(melodyReverse);

  const changeZoom = (state) => {
    setZoom( typeof(state) === 'boolean' ? state : !zoom);
    if (!(typeof(state) === 'boolean') && !zoom) {
      audio.playbackRate = 1;
      audio.play();
    } else if (zoom) {
      audioReverse.playbackRate = 1.3;
      audioReverse.play();
    }
  }

  return (
    <div className='relative'>
      <div className={`card-interactive${zoom ? ' active' : ''}`} 
        tabIndex={1} 
        onClick={changeZoom}
        onBlur={() => changeZoom(false)}>
        <div className='card-translate relative'>
          <div className='card-rotator'>
            <div className='card-back'>
              {
                card && card.imagen &&
                <picture>
                    <source srcSet={(category === 1 ? backImgCommon : category === 2 ? backImgSpecial : category === 3 ? backImgPremium : backImgHologram).desk_avif }
                    type="image/avif" />
                    <source srcSet={(category === 1 ? backImgCommon : category === 2 ? backImgSpecial : category === 3 ? backImgPremium : backImgHologram).desk_webp }
                    type="image/webp" />
                  <img className='col-1'
                    src={(category === 1 ? backImgCommon : category === 2 ? backImgSpecial : category === 3 ? backImgPremium : backImgHologram).desk}
                    onLoad={() => setLoaded('')}
                    alt="" width={640} height={960} />
                </picture>
              }
            </div>
            <div className='card-front relative'>
              {
                card && card.imagen &&
                <picture>
                  <source srcSet={`${ card.imagen }-sm.avif 1x, ${ card.imagen }.avif 2x`}
                    type="image/avif" media='(max-width: 768px)' />
                  <source srcSet={`${ card.imagen }-sm.webp 1x, ${ card.imagen }.webp 2x`}
                    type="image/webp" media='(max-width: 768px)' />
                  <source srcSet={`${ card.imagen }.avif 1x, ${ card.imagen }@2.avif 2x`}
                    type="image/avif" />
                  <source srcSet={`${ card.imagen }.webp 1x, ${ card.imagen }@2.webp 2x`}
                    type="image/webp" />
                  <img className='col-1'
                    srcSet={`${ card.imagen }.jpg 1x, ${ card.imagen }@2.jpg 2x`}
                    src={`${ card.imagen }.jpg`}
                    onLoad={() => setLoaded('')}
                    alt="" width={640} height={960} />
                </picture>
              }
            </div>
          </div>
        </div>
      </div>
      <span className={`cnt card-animation ${loaded}`}>
        <span className='line top'></span>
        <span className='line bottom'></span>
      </span>
    </div>
  );
}


export { CardImage }
