import React, { useEffect } from 'react';
import SEO from 'providers/HelmetProvider';
import changeNameImages from 'utils/changeNameImages';
import Button from 'components/buttons/buttons';
import UserService from 'services/userService.service';
import { HomeTutorial } from './tutorial/homeTutorial';
import { useUserContext } from 'providers/UserProvider';
import Layout from 'components/routes/Layout';
import './home.scss';
import melody from 'assets/audio/speed-car-cuted.mp3'

const Home = () => {
  const bg_home = changeNameImages('/assets/images/home/bg-home.jpg');
  const checo = changeNameImages('/assets/images/home/cheeco.png');
  const { user, setUser } = useUserContext(); 
  const audio = new Audio(melody);

  const validateToken = async () => {
    try {
      const res = await UserService.validToken();
      if (!res) {
        setUser(null);
      }
    } catch (err) {
      console.error(err);
    }
  }

  const playSound = () => {
    audio.playbackRate = 2.0;
    audio.play();
  }

  useEffect(() => {
    if (user) {
      validateToken();
    }
  }, []);

  return (
    <>
      <SEO
        title={'Checo Cards | Inicio'}
        description={'Conviértete en el más rápido explorador y colecciona todas las tarjetas de Checo Pérez.'}
      />
      <Layout id='home' classes='relative flex flex-column flex-between'>
        <div className='bg-checo cnt full d-md-block' hidden>
          <picture>
            <source srcSet={bg_home.desk_avif + ' 1x, ' + bg_home.desk2_avif + ' 2x'}
              type="image/avif" />
            <source srcSet={bg_home.desk_webp + ' 1x, ' + bg_home.desk2_webp + ' 2x'}
              type="image/webp" />
            <img className='full cnt'
              srcSet={bg_home.desk + ' 1x, ' + bg_home.desk2 + ' 2x'}
              src={bg_home.desk}
              alt=''
              width={1440}
              height={980} />
          </picture>
        </div>
        <section className='col-1'>
          <div className='banner pt-24'>
            <div className='bg-checo cnt full d-md-none'>
              <picture>
                <source srcSet={bg_home.desk_avif + ' 1x, ' + bg_home.desk2_avif + ' 2x'}
                  type="image/avif" />
                <source srcSet={bg_home.desk_webp + ' 1x, ' + bg_home.desk2_webp + ' 2x'}
                  type="image/webp" />
                <img className='full cnt'
                  srcSet={bg_home.desk + ' 1x, ' + bg_home.desk2 + ' 2x'}
                  src={bg_home.desk}
                  alt=''
                  width={375}
                  height={420} />
              </picture>
            </div>
            <div className='pt-24 px-container pb-24 mt-24'>
              <img className='logo-checo col-1 mx-auto relative'
                src={'/assets/images/home/logo-checo-cards.svg'}
                alt='Logo - Checo cards'
                width={150}
                height={57} />
            </div>
          </div>
        </section>
        <section className='relative col-1'>
          <div className='info flex flex-column flex-sm-row flex-stretch'>
            <div className='col-1 col-md-2 flex flex-center py-24 px-12'>
              <h2 className='text-venera text-white text-center mb-18'>Gracias #TeamCheco por participar en Checo Cards. <br />
                Próximamente regresaremos con nuevas noticias.</h2>
            </div>
            <div className='col-1 col-md-2'>
              <div className='description-card px-container py-24 relative flex'>
                <div className='description-card-content mx-auto col-1'>
                  <h1 className='text-venera text-center mb-12 mt-6 mb-md-6'><span>Explora & <br />
                    colecciona</span></h1>
                  <div className='px-container mb-24'>
                    {user ?
                      <>
                        <p className='h3 text-center text-user'>¡Hola, {user?.nombre}! </p>
                        <p className='text-center'>Conviértete en el más rápido explorador y colecciona todas las tarjetas de Checo Pérez</p>
                      </>
                      :
                      <p className='text-center'>Regístrate o inicia sesión para vivir <br /> la experiencia completa con Checo Pérez</p>}
                  </div>
                  {user ?
                    <div className='mb-24'
                      onClick={playSound}>
                      <Button
                        description='Comenzar'
                        color="blue"
                        link="/mi-album" />
                    </div>
                    :
                    <div className='mb-24'>
                      <div className='mb-18 mb-md-6'>
                        <Button
                          description='Inicia sesión'
                          color="blue"
                          link="/login" />
                      </div>
                    </div>}
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export { Home }
