import React from "react";
import { Link } from "react-router-dom";

const Button = ({description, link, color}) => {
  return (
    <Link 
      to={link}
      className={"btns btns-" + color}>
      <span>{ description }</span>
    </Link>
  );
}

export default Button;