import React from "react";
import { motion } from "framer-motion";

const timeTransition = .5;
const pageTransitionsProps = {
  initial: {opacity: 0},
  animate: {
    opacity: 1,
    transition: {
      duration: timeTransition * .8,
      ease: [.36,.83,.33,.98]
    }
  },
  exit: {
    opacity: 0,
    transition: { 
      duration: timeTransition * .5,
      ease: [.5,.1,1,.6]
    }
  }
}

const Layout = ({children, id, classes}) => {
  return(
    <>
      <motion.main 
        id={id}
        className={classes}
        {...pageTransitionsProps}
      >
        { children }
      </motion.main>
    </>
  );
}

export default Layout;