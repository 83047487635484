import React, { useState, useContext, useEffect } from "react";

const userContext = React.createContext();

export function useUserContext() {
  return useContext(userContext);
}

export function UserProvider(props) {
  const [user, setUser] = useState(null);

  const getUserFromStorage = () => { 
    const user = JSON.parse(localStorage.getItem('CHECO_USER'));
    const token = localStorage.getItem('CHECO_TOKEN');
    const userData = {...user, token: token};
    if (user && token) {
      setUser(userData);
    }
  }

  useEffect(() => {
    getUserFromStorage();
  }, []);

  return (
    <userContext.Provider value={{ user, setUser }}>
      {props.children}
    </userContext.Provider>
  );
}
