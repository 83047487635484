import React, { useState, useEffect } from 'react'; 
import axios from "axios";

const ax = axios.create(); 

const useAxiosLoader = () => {
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const inc = mod => setCounter(c => c + mod);

    const handleRequest = config => {
      inc(1);

      const token = localStorage.getItem('CHECO_TOKEN');
      if (token) {
        config.headers['token'] = token;
      }

      return config;
    };
    const handleResponse = response => (inc(-1), response);
    const handleError = error => (inc(-1), Promise.reject(error));

    const reqInterceptor = ax.interceptors.request.use(handleRequest, handleError);
    const resInterceptor = ax.interceptors.response.use(handleResponse, handleError);
    return () => {
      ax.interceptors.request.eject(reqInterceptor);
      ax.interceptors.response.eject(resInterceptor);
    };
  }, []);

  return counter > 0;
};

const GlobalLoader = () => {
  const loading = useAxiosLoader();
  return (
    <>
      {
        loading && (
          <div className="loader-container full flex flex-center cnt">
            <div className="loader"></div>
          </div>
        )
      }
    </>
  );
}


export { GlobalLoader, ax };