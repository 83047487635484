import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { Header } from './components/header/header';
import { Footer } from './components/footer/footer';
import { RoutesWithAnimation } from 'components/routes/RoutesWithAnimation';
import { UserProvider } from 'providers/UserProvider';
import { GlobalLoader } from 'providers/LoaderProvider';
import SEO from 'providers/HelmetProvider';


const App = () => {
  return (
    <>
      <SEO
        title={'Checo Cards'}
        description={'Conviértete en el más rápido explorador y colecciona todas las tarjetas de Checo Pérez.'}
      />
      <UserProvider>
        <BrowserRouter>
          <Header />
          <RoutesWithAnimation />
          <Footer />
        </BrowserRouter>
        <GlobalLoader />
      </UserProvider> 
    </>
  );
};

export default App;
