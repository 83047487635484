import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import SEO from 'providers/HelmetProvider';

import { ImageCard } from './imageCard';
import changeNameImages from 'utils/changeNameImages';
import UserService from 'services/userService.service';
import { ax } from 'providers/LoaderProvider';
import { Toastify } from 'components/toastify/toastify';
import Layout from 'components/routes/Layout';
import './album.scss';

function Album() {
  const navigate = useNavigate();
  const bg = changeNameImages('/assets/images/global/bg.png');
  
  const location = useLocation();
  const dataState = location.state;
  const toastifyRef = useRef();
  const [cards, setCards] = useState(Array.from({ length: 100 }, () => 0))
  const [cardsPerPage, setCardsPerPage] = useState([]);
  const [activePage, setActivePage] = useState(0);
  const [totalCards, setTotalCards] = useState(100);
  const [totalPages, setTotalPages] = useState(10);

  const changePage = (move) => {
    const auxActivePage = activePage + move;
    if(auxActivePage < 0 || auxActivePage >= totalPages) return;
    setCardsPerPage(loadWhiteCards());
    setActivePage(auxActivePage);
    window.scrollTo(0, 0);
  }

  const getAlbum = async (totalCardsUpdated) => {
    const token = localStorage.getItem('CHECO_TOKEN');
    const user = JSON.parse(localStorage.getItem('CHECO_USER'));
    const headerConfig = {
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'token': token
      }
    };
    ax
      .get(
        (`${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_ENDPOINT_GET_ALBUM}`).replace('@userId', user.uid),
        headerConfig)
      .then(async (res) => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error(error.response.data.msg || 'Ocurrió un error inesperado.');
        } else {
          let arrayAux = Array.from({ length: totalCardsUpdated }, () => 0);
          res.data.data.forEach((item) => {
            arrayAux[item.card.CardNumber - 1] = item;
          });
          setCards(arrayAux);
        }
      })
      .catch((error) => {
        toastifyRef.current.notify(
          'error', 
          error.response.data.msg || 'Ocurrió un error inesperado'
        )
      })
  }

  const getCountCards = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_ENDPOINT_GET_COUNTER_CARDS}`, 
        {
          method: 'GET',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      );
      if (response.status !== 200) {
        throw new Error(error.response.data.msg || 'Ocurrió un error inesperado.');
      }
      const res = await response.json();
      if (totalCards < res.count) {
        setTotalCards(res.count)
        getAlbum(res.count);
      } else {
        getAlbum(totalCards);
      }
    } catch (error) {
      toastifyRef.current.notify(
        'error', 
        error.response.data.msg
      );
    }
  }

  const validateToken = async () => {
    try {
      const res = await UserService.validToken();
      if (res) {
        getCountCards();
      } else {
        navigate('/login');
      }
    } catch (err) {
      toastifyRef.current.notify(
        'error', 
        err.response.data.msg || 'Tu sesión caducó'
      );
    }
  }

  const loadWhiteCards = () => {
    return Array.from({ length: 10 }, () => 0);
  }

  useEffect(() => {
    const minLength = activePage * 10;
    let auxImages = [];
    let auxCards = cards.filter((card, i) => {
      auxImages.push(false);
      return i >= minLength && i < minLength + 10;
    });
    setCardsPerPage(auxCards);
  }, [activePage, cards]);

  useEffect(() => {
    setTotalPages(Math.ceil(totalCards / 10));
  }, [totalCards])

  useEffect(() => {
    validateToken();
    if (dataState && dataState.albumPage) {
      setActivePage(dataState.albumPage);
      window.history.replaceState({}, '')
    }
  }, [])

  return (
    <>
      <SEO 
        title={'Checo Cards | Mi albúm'}
        description={'Conviértete en el más rápido explorador y colecciona todas las tarjetas de Checo Pérez.'}
      />
      <Layout id='album' classes='pt-main pb-18 relative'>
        <div className='bg cnt-h col-1'>
          <picture>
            <source srcSet={bg.sm_avif + ' 1x, ' + bg.sm2_avif + ' 2x'}
              type="image/avif" media='(max-width: 768px)' />
            <source srcSet={bg.sm_webp + ' 1x, ' + bg.sm2_webp + ' 2x'}
              type="image/webp" media='(max-width: 768px)' />
            <source srcSet={bg.desk_avif + ' 1x, ' + bg.desk2_avif + ' 2x'}
              type="image/avif" />
            <source srcSet={bg.desk_webp + ' 1x, ' + bg.desk2_webp + ' 2x'}
              type="image/webp" />
            <img className='col-1'
              srcSet={bg.desk + ' 1x, ' + bg.desk2 + ' 2x'}
              src={bg.desk}
              alt=''
              width={375}
              height={420} />
          </picture>
        </div>
        <section className='pb-24 mb-24 relative'>
          <h1 className='text-venera text-center'>Mi álbum</h1>

          <div className='content-grid flex flex-wrap flex-stretch pb-24 mt-24 px-container mx-auto'>
            {cardsPerPage.map((card, i) => {
              const index = i + 1 + (activePage * 10);
              return (
                <div className='card col-2 col-sm-3 col-md-4 py-6 px-12 mb-12' key={i}>
                  <div className={`box relative${!card ? ' inactive' : ''}`} 
                    data-index={(index < 10 ? '0' : '') + index}>
                    {card && card.card &&
                      <ImageCard card={card} totalCards={totalCards} activePage={activePage} />}
                  </div>
                </div>
              );
            })}
          </div>

          <div className='paginator flex flex-center'>
            <button aria-label='Página anterior'
              type='button'
              className='mx-6'
              onClick={() => changePage(-1)}>
              <img src="/assets/images/global/arrow-back-circle.svg"
                className='col-1'
                alt="" width={24} height={24} />
            </button>
            <p className='h3 text-white'><b><span className='text-blue'>{(activePage + 1 < 10 ? '0' : '') + (activePage + 1)}</span> de {(totalPages < 10 ? '0' : '') + totalPages}</b></p>
            <button aria-label='Página siguiente'
              type='button'
              className='mx-6'
              onClick={() => changePage(1)}>
              <img src="/assets/images/global/arrow-back-circle.svg"
                className='col-1'
                alt="" width={24} height={24} />
            </button>
          </div>
        </section>
        <Toastify ref={toastifyRef} />
      </Layout>
    </>
  )
}

export { Album }
